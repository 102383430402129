<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="payModule">
            <div class="model">
                <div class="leftBtn">包月</div>
                <div class="content">
                    <p>包月<span>使用30天</span></p>
                    <p>30天内任意查看所有达人、大师预测心水，不限次数</p>
                </div>
                <div class="price">
                    <p>168元</p>
                    <p class="origin">原价:668</p>
                </div>
                <div class="rightBtn">
                    <img src="../../assets/images/checked.png" alt="">
                </div>
            </div>
        </div>
        <div class="discount">
            <div class="model">
                <div class="pDiv">
                    <p>使用我的剩余太阳购买：0</p>
                    <p>还剩：0</p>
                </div>
                <div class="choose" :class="{ 'chooseImg': show}" @click="choose">
                    <img :hidden="show" src="../../assets/images/checked.png" alt="">
                </div>
            </div>
        </div>
        <div class="proxy">
            <input type="checkbox" name="" id="">
            <span>我已阅读并同意<span class="gotoProxy" @click="goPage('appreciate')">《增值服务协议》</span></span>
        </div>
        <div class="desc">
            <p> 【注】：</p>
            <p>1、包月是指从开通的当天算起+30天。</p>
            <p>2、不一定每个大师每期都会发表预测，自行承担风险。</p>
            <p>3、大师预测心水，仅只代表个人观点，码无绝对，预测有风险，参考需谨慎，量力而为。</p>
            <p>4、若遇到任何问题，请联系客服</p>
        </div>
        <div class="pay">
            <button>确定支付</button>
        </div>
    </div>
    
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            show:false
        }
    },
    components:{
        vHeader
    },
    methods:{
        choose(){
            this.show = !this.show
        }
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        .payModule{
            width: 100%;
            height: 110px;
            margin: 10px 0;
            font-size: 14px;
            background-color: #fff;
            .model{
                width: 96%;
                height: 110px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .leftBtn{
                    width: 36px;
                    height: 16px;
                    border: 2px solid #f4ea2a;
                    border-radius: 6px;
                    color: #f4ea2a;
                    text-align: center;
                    line-height: 16px;
                }
                .content{
                    width: 140px;
                    p{  
                        span{
                            font-size: 10px;
                        }
                    }
                }
                .price{
                    align-self: flex-start;
                    .origin{
                        padding-top: 0px;
                        color: #999;
                    }
                }
                .rightBtn{
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .discount{
            width: 100%;
            height: 60px;
            background-color: #fff;
            font-size: 14px;
            
            .model{
                width: 96%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                .choose{
                    
                    width: 24px;
                    height: 24px;
                    
                }
                .pDiv{
                    height: 100%;
                    p{
                        padding-left: 40px;
                        height: 10px;
                        text-align: right;
                    }
                }
                img{
                    width: 24px;
                    height: 24px;
                }
                .chooseImg{
                    border: 1px solid #ccc;
                    border-radius: 50%;
                }
            }
        }
        .proxy{
            input{
                vertical-align: middle;
                width: 12px;
                height: 12px;
            }
            span{
                vertical-align: middle;
                font-size: 12px;
                color: #aaa;
                .gotoProxy{
                    color: #000;
                    display: inline-block;
                    
                }
            }
        }
        .desc{
            position: absolute;
            top:220px;
            left: 10px;
            margin-top: 50px;
            height: 160px;
            p{
                font-size: 12px;
                width: 88%;
                height: auto;
                line-height: 16px;
                padding: 0;
                margin: 0;
                margin-bottom: 6px;
            }
        }
        .pay{
            width: 100%;
            height: 38px;
            position: fixed;
            bottom: 0;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            button{
                margin: 0 auto;
                width: 98%;
                height: 90%;
                border: none;
                background-color: #f4ea2a;
                border-radius: 4px;
            }
        }
    }
</style>